//css .type4
.type4 {
	height: 100%;
	box-shadow: 0px 10px 40px 0px rgba(6, 22, 58, .1);
	border: 1px solid #e8e8e8;
	.date-event {
	    position: relative;
	    display: flex;
	    flex-direction: column;
	    margin: 0 30px;
	    padding-bottom: 20px;
	    padding-top: 20px;
	    border-bottom: 1px solid #eeeeee;
	    top: 20px;
		.date-month {
		    font-size: 45px;
		    height: 20px;
		    line-height: 0px;
		    font-weight: 500;
		    letter-spacing: -0.5px;
	        color: #df193a;
	        .month {
				font-size: 16px;
			    text-transform: uppercase;
			    font-weight: 500;
			    color: var(--heading);
			}
		}
		.weekday {
		    font-size: 20px;
		    font-weight: 700;
		    text-transform: uppercase;
		    color: #444444;
		}
		
	}
	.desc {
		.event_post {
		    padding: 40px 25px 40px 25px;
		    text-align: left;
		    border-top: 0;
		    -webkit-transition: all 3s ease;
		    -o-transition: all 3s ease;
		    transition: all .3s ease;
			@media(min-width: 1024px) and (max-width: 1099px){
				padding-left: 20px;
				padding-right: 20px;
			}
			.event_title {
				margin: 0 0 18px 0;
				&:hover {
					color: var(--primary);
				}
			}
			.time-event {
				font-size: 16px;
			    line-height: 22px;
			    font-weight: 400;
			    color: #444444;
				.time {
					margin-bottom: 7px;
					.more_date_text {
						&:hover {
							color: var(--heading);
						}
					}
				}
				.time, .venue {
					display: flex;
					align-items: center;
					gap:5px;
					i {
						color: var(--secondary);
					}
				}
			}
			.button_event {
				margin-top: 30px;
				display: inline-flex;
				align-items: center;
				.view_detail {
					transition: all .3s;
					background: #fff;
					border: 1px solid #e0e0e0;
					font-weight: 500;
					color: var(--heading);
					font-size: 16px;
					line-height: 1.2;
					padding: 8px 20px;
					&:hover {
						color: #fff;
						background-color: var(--primary);
                        border-color: var(--primary);
					}
				}
			}
		}
	}
}