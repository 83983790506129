.ovaev-event-share {
	.share-social-icons {
	    margin: 0;
	    padding: 0;
	    list-style: none;

	    li {
	    	margin: 0;
	    	padding: 0;
    		display: inline-block;

    		a {
    			color: #fff;
			    background-color: #ff3514;
			    padding: 0;
			    border-radius: 50%;
			    margin-bottom: 0px;
			    display: inline-flex;
			    width: 40px;
			    height: 40px;
			    justify-content: center;
			    align-items: center;
			    -webkit-transition: all .3s ease;
			    -moz-transition: all .3s ease;
			    -o-transition: all .3s ease;
			    transition: all .3s ease;

			    &:hover {
			    	background-color: #06163A !important;
			    }

			    &.ico-facebook {
			    	background-color: #3b5998;
			    }
			    &.ico-twitter {
			    	background-color: #18a6f0;
			    }
			    &.ico-pinterest {
			    	background-color: #dd4b39;
			    }
			    &.ico-linkedin {
			    	background-color: #4c5fd7;
			    }
    		}
	    }
	}
}