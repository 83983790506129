// css .type2
.type2 {
	position: relative;
	display: flex;
	height: 100%;
	background-color: #fff;
	box-shadow: 0px 10px 40px 0px rgba(6, 22, 58, .1);
	
	.desc {
		display: flex;
		width: 100%;
		@media ( max-width: 1024px ) {
			flex-direction: column;
		}
		.event-thumbnail {
			background-size: 0;
		    background-repeat: no-repeat;
		    background-position: center;
		    width: 43%;
		    @media ( max-width: 1024px ) {
				width: 100%;
			}
			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.event_post {
			transition: all .3s ease;
			width: 57%;
			margin: auto;
			padding: 40px 30px;
			@media(min-width: 1024px) and (max-width: 1099px){
				padding: 35px 25px; 
			}
			@media ( max-width: 1024px ) {
				width: 100%;
			}

			.post_cat {
				margin-bottom: 12px;
				.event_type {
					color: #fff;
					line-height: 1.2;
					font-weight: 500;
					border-radius: 5px;
					padding: 5px 10px;
					background-color: var(--primary);
					&:hover {
						background-color: var(--secondary);
					}
				}
			}
			.event_title {
				margin: 0 0 18px 0;
				font-size: 24px;
				&:hover {
					color: var(--primary);
				}
			}
			.time-event {
				color: #444444;
				font-size: 16px;
				font-weight: 400;
				.time {
					margin-bottom: 7px;
					.more_date_text {
						&:hover {
							color: var(--heading);
						}
					}
				}
				.time, .venue {
					display: flex;
					align-items: center;
					gap: 5px;
					i {
						color: var(--secondary);
					}
				}
			}
			.button_event {
				margin-top: 30px;
				display: inline-flex;
				align-items: center;
				.view_detail {
					transition: all .3s;
					background: #fff;
					border: 1px solid #e0e0e0;
					font-weight: 500;
					color: var(--heading);
					font-size: 16px;
					line-height: 1.2;
					padding: 8px 20px;
					&:hover {
						color: #fff;
						background-color: var(--primary);
                        border-color: var(--primary);
					}
				}
			}
		}
	}
}